import React, { useState, useEffect } from "react";
import Navbar from "../Componets/Navbar";
import Slider from "../Componets/Slider";
import "./Products.css";
import Footer from "./../Componets/Footer";

import HomePageProduct from "../Componets/HomePageProduct";
import MainApplication from "../Componets/MainApplication";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Product() {
  const [bannerData, setBannerData] = useState([]);
  const [productData, setProductData] = useState([]);
  // const [applicationData, setApplicationData] = useState([]);
  // const [safetyBoard, setSafetyBoard] = useState({});

  const bannerList = useSelector((state) => state.client.bannerData);
  const productList = useSelector((state) => state.client.productsData);
  // const applicationList = useSelector((state) => state.client.applicationData);
  useEffect(() => {
    if (bannerList && productList) {
      setBannerData(bannerList);
      setProductData(productList);
      // setApplicationData(applicationList);
    }
  }, [bannerList, productList]);

  useEffect(() => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <Slider bannerData={bannerData} />

      {/* products */}
      <div className="container product__page__block">
        <div className="row product__page__ineer__block">
          <div className="section__title">Product</div>
          <div className="product__block">
            {productData.map((product) => {
              return (
                <>
                  <div className="product__page__product">
                    <img
                      className="product__page__product__image"
                      src={product.product_image}
                      alt=""
                    />
                    <p className="product__page__product__name">
                      {product.product_name}
                    </p>
                    <p className="product__page__product__details">
                      {product.product_description}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <MainApplication />

      <Footer />
    </>
  );
}

export default Product;
