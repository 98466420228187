/*global google*/
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import mapIcon from "./../assets/Icon/mapIcon.png";
import mail_filled from "./../assets/Icon/mail_filled.svg";
import phone_filled from "./../assets/Icon/phone_filled.svg";
import satisfaction from "./../assets/Images/satisfaction.jpg";

import rb__footer__logo from "./../assets/logo/rb__footer__logo.png";
import "./Footer.css";
// import calltoMap from "../Functions/calltoMap";
function Footer() {
  // const mapRef = useRef();

  //   function initMap() {
  //   // The location of Uluru
  //   const uluru = { lat: -25.344, lng: 131.036 };
  //   // The map, centered at Uluru
  //   const map = new google.maps.Map(document.getElementById("map"), {
  //     zoom: 4,
  //     center: uluru,
  //   });
  //   // The marker, positioned at Uluru
  //   const marker = new google.maps.Marker({
  //     position: uluru,
  //     map: map,
  //   });
  // }
  // initMap()

  // useEffect(() => {
  //   // const locations = [{ coordinates: [-118.348256, 34.025482] }];

  //   calltoMap();
  //   // console.log("top");
  //   // window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      {/* <div className="container_fluid footer__contact__block">
        <div className="inner__footer__contact__block">
          <div className="container footer__contact__title__block">
            <p className="footer__contact__title">
              Looking for the best automation solution for your company?
            </p>
            <Link className="footer__contact__button" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div> */}

      <div className="container-fluid">
        <div className="container location__block">
          <div className="row inner__location__block">
            <div className="col-md-5 col-sm-12 address__block">
              <div className="address__title__block">
                <img src={mapIcon} alt="mapIcon" />
                <p className="address__title">Office Address</p>
              </div>
              <p className="full__address__text">
              156, Saikrupa Society,
                <br />Opp. Sangana Society,
                <br />
                Near Shyamdham Chowk,
                <br /> Punagam, SURAT 395010
              </p>
              <p className="footer__contact__tab">
                <img
                  className="footer__tab__icon"
                  src={mail_filled}
                  alt="mail"
                />
                <a href="mailto:sales1@wicon.in">rblabels20@gmail.com</a>
              </p>
              <p className="footer__contact__tab">
                <img
                  className="footer__tab__icon"
                  src={phone_filled}
                  alt="phone"
                />
                <a href="tel:9898603537">+91 98257 32698</a>
              </p>
              
            </div>
            <div
            //   ref={mapRef}
              id="map"
              className="col-md-7 col-sm-12 map__Container map__block"
            >
              <img className="footer__image" src={satisfaction}></img>
            </div>
            {/* <Map
              google={google}
              containerStyle={{
                position: "static",
                width: "100%",
                height: "100%",
              }}
              style={{
                width: "100%",
                height: "100%",
              }}
              center={locations[0]}
              initialCenter={locations[0]}
              zoom={locations.length === 1 ? 18 : 13}
              disableDefaultUI={true}
            >
              {locations.map((coords) => (
                <Marker position={coords} />
              ))}
            </Map> */}
          </div>
        </div>
      </div>
      {/* <div
        // ref={mapRef2}
        id="map"
        className=" map__Container map__block"
      >
        <img className="footer__image" src={satisfaction}></img>
      </div> */}
      <div className="container-fluid footer__block">
        <div className="container inner__footer__block">
          <div className="footer__left__side">
            <img className="footer__logo" src={rb__footer__logo} alt="" />
            <div className="footer__link__block">
              <Link to="/" className="footer__link">
                Home
              </Link>
              <Link to="/products" className="footer__link">
                Products
              </Link>
           
              <Link to="/application" className="footer__link">
                Application
              </Link>
            
              
              <Link to="/contact" className="footer__link">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="footer__right__side">
            <p className="footer__contact__link">
            <img
                  className="footer__tab__icon"
                  src={mail_filled}
                  alt="mail"
                />
              <a href="mailto:sales1@wicon.in">rblabels20@gmail.com</a>
            </p>
            <p className="footer__contact__link">
            <img
                  className="footer__tab__icon"
                  src={phone_filled}
                  alt="phone"
                />
              <a href="tel:9898603537">+91 98257 32698</a>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container copyright__block">
          <p className="empty__copyright__text"></p>
          <p className="copright__text">
            &copy; Copyright 2022 RB Labels. All Rights Reserved.
            Developed By @spexy infotech{" "}
          </p>
        </div>
      </div>
    </>
  );
}
export default Footer;
