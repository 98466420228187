import React,{useState, useEffect} from 'react'
import "./Slider.css"
import left__arrow from "./../assets/Icon/left__arrow.svg"
import right__arrow from "./../assets/Icon/right__arrow.svg"


  function Slider({bannerData}) {
    
    // const [people, setPeople] = useState();
    const [index, setIndex] = useState(0);
    
  
    useEffect(() => {
      if (index > bannerData.length - 1) {
        let newIndex = 0;
        setIndex(newIndex);
      }
      if (index < 0) {
        let newIndex = bannerData.length - 1;
        setIndex(newIndex);
      }
    }, [index, bannerData]);
  
  
    useEffect(()=>{
      let slider = setInterval(() => {
        setIndex(index + 1)
      }, 3000);
      return () => {clearInterval(slider)}
    })
  
    return (
      <main>
        <div className="App"></div>
        <div className="review__container">
          {bannerData.map((person, personindex) => {
            const { id, banner_image } = person;
  
            let position = "nextSlide";
            if (personindex === index) {
              position = "activeSlide";
            }
            // console.log(personindex, index, position);
            if (
              personindex === index - 1 ||
              (index === 0 && personindex === bannerData.length - 1)
            ) {
              position = "lastSlide";
            }
  
            return (
              <article className={position} key={id}>
                <img className="image" src={banner_image}  />
                {/* <h3>{name}</h3> */}
                {/* <p className="title">{title}</p> */}
                {/* <p>{quote}</p> */}
                {/* <FaQuoteRight className="icon" /> */}
              </article>
            );
          })}
          <div className="button-container">
            <button className="button" onClick={() => setIndex(index - 1)}>
              <img src={left__arrow} alt="left"></img>
            </button>
            <button className="button" onClick={() => setIndex(index + 1)}>
              <img src={right__arrow} alt="right"></img>
            </button>
          </div>
        </div>
      </main>
    );
  }
  
  export default Slider;
  