import React, { Suspense, lazy, useState, useEffect } from "react";
import { loadClientAllData } from "./Redux/clientAction";
import { useDispatch } from "react-redux";
import logo from "./logo.svg";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Loader from "./Componets/Loader";
import Products from "./Pages/Products";
import Application from "./Pages/Application";
import Contact from "./Pages/Contact";

import RBAdmin from "./RBAdminPages/RBAdmin";
import Admin from "./RBAdminPages/Admin";

import BannerData from "./RBAdminPages/Pages/Banner/BannerData";
import BannerCreate from "./RBAdminPages/Pages/Banner/BannerCreate";
import BannerUpdate from "./RBAdminPages/Pages/Banner/BannerUpdate";

import ApplicationData from "./RBAdminPages/Pages/Application/ApplicationData";
import ApplicationCreate from "./RBAdminPages/Pages/Application/ApplicationCreate";
import ApplicationUpdate from "./RBAdminPages/Pages/Application/ApplicationUpdate";

import ProductData from "./RBAdminPages/Pages/Product/ProductData";
import ProductCreate from "./RBAdminPages/Pages/Product/ProductCreate";
import ProductUpdate from "./RBAdminPages/Pages/Product/ProductUpdate";

import AdminContact from "./RBAdminPages/Pages/AdminContact";

const Home = lazy(() => import("./Pages/Home"));
// const Products = lazy(() => import("./Pages/Products"));
// const Application = lazy(() => import("./Pages/Application"));
// const Contact = lazy(() => import("./Pages/Contact"));

function App() {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClientAllData());
  }, []);
  // if (loading) {
  //   return <h1>Loading...</h1>;
  // }

  return (
    <>
      {/* <Router> */}
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/:page" element={<Home />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/application" element={<Application />}></Route>
            <Route path="/Contact" element={<Contact />}></Route>

            <Route path="/rbadmin" element={<RBAdmin />}></Route>
            <Route path="/admin" element={<Admin />}></Route>

           <Route path="/admin/bannerData" element={<BannerData/>}></Route>
           <Route path="/admin/banner/bannerCreate" element={<BannerCreate/>}></Route>
           <Route path="/admin/banner/:id" element={<BannerUpdate/>}></Route>

           <Route path="/admin/applicationData" element={<ApplicationData/>}></Route>
           <Route path="/admin/application/applicationCreate" element={<ApplicationCreate/>}></Route>
           <Route path="/admin/application/:id" element={<ApplicationUpdate/>}></Route>

           <Route path="/admin/productData" element={<ProductData/>}></Route>
           <Route path="/admin/product/productCreate" element={<ProductCreate/>}></Route>
           <Route path="/admin/product/:id" element={<ProductUpdate/>}></Route>

           <Route path="/admin/contact" element={<AdminContact/>}></Route>

          </Routes>
        </Suspense>
      {/* </Router> */}
    </>
  );
}

export default App;
