import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../Navbar";
import { Link } from "react-router-dom";
import "./ProductData.css";
import Loader from "../../../Componets/Loader";
function ProductData() {
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);

  const getList = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/productList`,
        {},
        {
          headers: {
            "api-key": "rblabels@123",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setProductData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const deleteProduct = (id) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/productDelete`,
        { product_id: id },
        {
          headers: {
            "api-key": "rblabels@123",
            Authorization: `Bearer ${token.split(" ")[1]}`,
          },
        }
      )
      .then((res) => {
        getList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  if (loading) {
    return (
      <>
        <Navbar />
      <Loader/>
      </>
    );
  }
  if (!loading) {
    return (
      <>
        <Navbar />
        <div className="container product__page__block">
          <div className="row product__page__ineer__block">
            <div className="section__title">Product</div>
            <Link to="/admin/product/productCreate">
              <button className="create__button">Create</button>
            </Link>
            <div className="product__block">
              {productData.map((product) => {
                return (
                  <div key={product.id} className="product__page__product">
                    <img
                      className="product__page__product__image"
                      src={product.product_image}
                      alt=""
                    />
                    <p className="product__page__product__name">
                      {product.product_name}
                    </p>
                    <p className="product__page__product__details">
                      {product.product_description}
                    </p>
                    <div className="product__crud__button">
                      <Link
                        className="edit__button"
                        to={`/admin/product/${product.id}`}
                      >
                        Edit
                      </Link>
                      <div
                        className="product__delete__button"
                        onClick={() => deleteProduct(product.id)}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductData;
