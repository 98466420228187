import React, { useState } from "react";
import Navbar from "../../Navbar";
import "./../form.css";
import axios from "axios";
import { useNavigate } from "react-router";

function BannerCreate() {
  const history = useNavigate();

  const token = localStorage.getItem("token");

  const [banner, setBanner] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBanner({ ...banner, [name]: value });
  };

 

  const handleUpdate = async (e) => {
    const data = new FormData(e.target);
    e.preventDefault();
    if (true) {
      await axios
        .post(`${process.env.REACT_APP_PROJECT_API_URL}/bannerPost`, data, {
        
          headers: {
            "api-key": "rblabels@123",
            Authorization: `Bearer ${token.split(" ")[1]}`,
          },
        })
        .then((res) => {
          history("./../../bannerData");
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      alert("please fill information");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid crud__container">
        <div className="container">
          <p className="page__title">Add Product</p>
        <img className="update__banner__image" src={banner.banner_image} alt="" />
          <form onSubmit={handleUpdate} className="form__container">
                        <div className="create__form__field">
              <label className="form__field__label" htmlFor="banner_image">
                Image
              </label>
              <input
                className="form__file__field"
                type="file"
                name="banner_image"
                id="banner_image"
                onChange={handleChange}
              />
            </div>
            <div className="create__form__field">
              <label
                className="form__field__label"
                htmlFor="banner_description"
              ></label>
              <div className="form__file__field">
                <input
                  className="submit__button"
                  type="submit"
                  value="Create"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
   
    </>
  );
}

export default BannerCreate;
