import React, { useEffect, useState } from "react";
import "./Navbar.css";
import rb__labels__logo from "./../assets/logo/rb__labels__logo.png";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "../Redux/loginAction";
function Navbar() {
  const { pathname } = useLocation();
  const [className, setClassName] = useState({
    home: "navbar__contact__tab active__navbar__link",
    productData: "navbar__contact__tab",
    application: "navbar__contact__tab",
    contact: "navbar__contact__tab",
  });

  useEffect(() => {
    let path = {
      bannerData: "navbar__contact__tab",
      productData: "navbar__contact__tab",
      applicationData: "navbar__contact__tab",
      contact: "navbar__contact__tab",
      login: "navbar__contact__tab",
      logout: "navbar__contact__tab",
    };

    if (pathname.includes("/bannerData")) {
      path.bannerData = "active__navbar__link navbar__contact__tab";
    } else if (pathname.includes("/productData")) {
      path.productData = "active__navbar__link navbar__contact__tab";
    } else if (pathname.includes("/applicationData")) {
      path.applicationData = "active__navbar__link navbar__contact__tab";
    } else if (pathname.includes("/contact")) {
      path.contact = "active__navbar__link navbar__contact__tab";
    } else if((pathname.includes("/rbadmin"))){
      path.login = "active__navbar__link navbar__contact__tab";
    } else if((pathname.includes("/rbadmin"))){
      path.logout = "active__navbar__link navbar__contact__tab";
    }
    setClassName(path)
  }, [pathname]);

  return (
    <>
      <div className="container-fluid main__navbar">
        <div className="container navbar">
          <Link to="/">
            <img src={rb__labels__logo} alt="wicon_white_logo" />
          </Link>
          <div className="navbar__contact__tab__block">
            <Link to="/admin/bannerData" className={className.bannerData}>
              Banner
            </Link>
            <Link to="/admin/productData" className={className.productData}>
              Product
            </Link>
            <Link to="/admin/applicationData" className={className.applicationData}>
              Application
            </Link>
            <Link to="/admin/contact" className={className.contact}>
              Contact
            </Link>
            <Link to="/rbadmin" className={className.login}>
              Login
            </Link>
            <Link to="/rbadmin" className={className.logout}>
            <button className="logout__button" onClick={logout}>Logout</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
