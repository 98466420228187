import React from "react";

import "./MainApplication.css";

import medical from "./../assets/mainApplication/medical.png";
import automotive from "./../assets/mainApplication/automotive.png";
import industrial from "./../assets/mainApplication/industrial.png";
import electronics from "./../assets/mainApplication/electronics.png";
import electrical__equipment from "./../assets/mainApplication/electrical__equipment.png";
import telecom from "./../assets/mainApplication/telecom.png";
import consumer__electronics from "./../assets/mainApplication/consumer__electronics.png";

function MainApplication() {
  return (
    <>
      <div className=" main__application__block">
        <p className="section__title">Application</p>
        <div className="row main__application__inner___block">
          <div className="first__row">
            <div className="application">
              <img className="application__image" src={medical} alt={medical} />
              <p className="application__name">Medical</p>
            </div>
            <div className="application">
              <img
                className="application__image"
                src={automotive}
                alt={automotive}
              />
              <p className="application__name">Automotive</p>
            </div>
            <div className="application">
              <img
                className="application__image"
                src={industrial}
                alt={industrial}
              />
              <p className="application__name">Industrial</p>
            </div>
            <div className="application">
              <img
                className="application__image"
                src={electronics}
                alt={electronics}
              />
              <p className="application__name">Electronics</p>
            </div>
          </div>
          <div className="second__row">
            <div className="application">
              <img
                className="application__image"
                src={electrical__equipment}
                alt={electrical__equipment}
              />
              <p className="application__name">
                Electrical
                <br />
                Equipment
              </p>
            </div>
            <div className="application">
              <img className="application__image" src={telecom} alt={telecom} />
              <p className="application__name">Telecom</p>
            </div>
            <div className="application">
              <img
                className="application__image"
                src={consumer__electronics}
                alt={consumer__electronics}
              />
              <p className="application__name">
                Consumer
                <br />
                Electronics
              </p>
            </div>
          </div>
          <div className="first__row">
            <div className="features">
              <p className="features__name">Waterproof nature</p>
            </div>
            <div className="features">
              <p className="features__name">Glossy / Matt coat</p>
            </div>
            <div className="features">
              <p className="features__name">UV coating</p>
            </div>
            <div className="features">
              <p className="features__name">Easy to apply</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainApplication;
