import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../Componets/Footer";
import Navbar from "../Componets/Navbar";
import Slider from "../Componets/Slider";
import "./Application.css";
import "./Home.css";
function Application() {
  const [bannerData, setBannerData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);

  const bannerList = useSelector((state) => state.client.bannerData);
  const applicationList = useSelector((state) => state.client.applicationData);
  useEffect(() => {
    if (bannerList && applicationList) {
      setBannerData(bannerList);
      setApplicationData(applicationList);
    }
  }, [applicationList, bannerList]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 690,
        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <>
      <Navbar />
      <Slider bannerData={bannerData} />
      <div className="container application__page__block">
        <div className="row application__page__inner__block">
          {applicationData.slice(0, 3).map((application) => {
            // console.log(
            //   application.application_tag.split(",").map((tag) => tag.trim())
            // );
            return (
              <>
                <div className="application__page__application">
                  <p className="section__title">
                    {application.application_name}
                  </p>
                  <img
                    className="safety__board__image"
                    src={application.application_image}
                    alt={application.application_name}
                  />
                  <div className="application__page__tag__block">
                    {application.application_tag
                      .split(",")
                      .map((tag) => tag.trim())
                      .map((tag) => {
                        return (
                          <>
                            <div className="tag__block">
                              <p className="tag__name">{tag}</p>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Application;
