import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar";
import "./../form.css";
import Loader from "./../../../Componets/Loader";

function ApplicationData() {
  // const token = useSelector(state => state.login.token)
  // useEffect(() => {
  //   console.log(token);
  // },[token]);
  const [applicationData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getList = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/applicationList`,
        {},
        {
          headers: {
            "api-key": "rblabels@123",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setApplicationData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getList(0);
  }, []);
  // console.log(applicationData);

  const deleteApplication = (id) => {
    const token = localStorage.getItem("token");
    // console.log(token);
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/applicationDelete`,
        { application_id: id },
        {
          headers: {
            "api-key": "rblabels@123",
            Authorization: `Bearer ${token.split(" ")[1]}`,
          },
        }
      )
      .then((res) => {
        getList();
        // console.log(res);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <Loader />
      </>
    );
  }
  if (!loading) {
    return (
      <>
        <Navbar />

        <div className="container application__page__block">
          <div className="row application__page__inner__block">
            <Link to="/admin/application/applicationCreate">
              <button className="create__button">Create</button>
            </Link>
            {applicationData.map((application) => {
              //  console.log(application);
              return (
                <div
                  key={application.id}
                  className="application__page__application"
                >
                  <p className="section__title">
                    {application.application_name}
                  </p>
                  <img
                    className="safety__board__image"
                    src={application.application_image}
                    alt={application.application_name}
                  />
                  <div className="application__page__tag__block">
                    {application.application_tag
                      .split(",")
                      .map((tag) => tag.trim())
                      .map((tag) => {
                        return (
                          <>
                            <div className="tag__block">
                              <p className="tag__name">{tag}</p>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <div className="crud__button">
                    <Link
                      className="edit__button"
                      to={`/admin/application/${application.id}`}
                    >
                      Edit
                    </Link>
                    <div
                      className="delete__button"
                      onClick={() => deleteApplication(application.id)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default ApplicationData;
