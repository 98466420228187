import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar";
import "./BannerData.css";
import "./../form.css";
import Loader from "../../../Componets/Loader";
function BannerData() {
  const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);

  const getList = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/bannerList`,
        {},
        {
          headers: {
            "api-key": "rblabels@123",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.data);
        setBannerData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const deleteBanner = (id) => {
    const token = localStorage.getItem("token");
    // console.log(token);
    axios
      .post(
        `${process.env.REACT_APP_PROJECT_API_URL}/bannerListDelete`,
        { banner_id: id },
        {
          headers: {
            "api-key": "rblabels@123",
            Authorization: `Bearer ${token.split(" ")[1]}`,
          },
        }
      )
      .then((res) => {
        getList();
        // console.log(res);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // console.log(bannerData);
  if (loading) {
    return (
      <>
        <Navbar />
        <Loader />
      </>
    );
  }
  if (!loading) {
    return (
      <>
        <Navbar />
        <div className="container-fluid section product__block">
          <div className="container ">
            <p className="page__section__title">Banner</p>
            <Link to="/admin/banner/bannerCreate">
              <button className="create__button">Create</button>
            </Link>
            {bannerData.map((ban) => {
              return (
                <div key={ban.id} className="row banner__image__block">
                  <img
                    className="banner__image"
                    src={ban.banner_image}
                    alt=""
                  />

                  <div className="col-md-8 col-sm-12 col-12 banner__details__box">
                    <div className="crud__button">
                      <Link
                        className="edit__button"
                        to={`/admin/banner/${ban.id}`}
                      >
                        Edit
                      </Link>
                      {/* <div
                        className="delete__button"
                        onClick={() => deleteBanner(ban.id)}
                      >
                        Delete
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default BannerData;
