import React from "react";
import { Link } from "react-router-dom";
import "./HomePageProduct.css";

function HomePageProduct({ product }) {
  return (
    <Link to="/products" className="product">
      <img className="product__image" src={product.product_image} alt="" />
      <div className="product__name__block">
        <p className="product__name">{product.product_name}</p>
      </div>
    </Link>
  );
}

export default HomePageProduct;
