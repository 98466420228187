import React, { useEffect, useState } from "react";
import "./Navbar.css";
import rb__labels__logo from "./../assets/logo/rb__labels__logo.png";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
function Navbar() {
  const { pathname } = useLocation();
  const [className, setClassName] = useState({
    home: "navbar__contact__tab active__navbar__link",
    products: "navbar__contact__tab",
    application: "navbar__contact__tab",
    contact: "navbar__contact__tab",
  });

  useEffect(() => {
    let path = {
      home: "navbar__contact__tab",
      products: "navbar__contact__tab",
      application: "navbar__contact__tab",
      contact: "navbar__contact__tab",
    };

    if (pathname.includes("/products")) {
      path.products = "active__navbar__link navbar__contact__tab";
    } else if (pathname.includes("/application")) {
      path.application = "active__navbar__link navbar__contact__tab";
    } else if (pathname.includes("/contact")) {
      path.contact = "active__navbar__link navbar__contact__tab";
    } else {
      path.home = "active__navbar__link navbar__contact__tab";
    }
    setClassName(path)
  }, [pathname]);

  return (
    <>
      <div className="container-fluid main__navbar">
        <div className="container navbar">
          <Link to="/">
            <img src={rb__labels__logo} alt="wicon_white_logo" />
          </Link>
          <div className="navbar__contact__tab__block">
            <Link to="/" className={className.home}>
              Home
            </Link>
            <Link to="/products" className={className.products}>
              Product
            </Link>
            <Link to="/application" className={className.application}>
              Application
            </Link>
            <Link to="/contact" className={className.contact}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
