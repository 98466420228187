import React from 'react'
import "./Loader.css"
function Loader() {
  return (
   <>
   <div className="loader__block">
       <div className="loader"></div>
   </div>
   </>
  )
}

export default Loader